<template>
  <form @submit.prevent="submit" class="forum-comments-form text-light">
    <form-group
      v-model="form.text"
      :errors="errors.text"
      id="text"
      placeholder="Digite um texto..."
      required
      html
    />

    <div class="d-flex justify-content-end">
      <button class="btn btn-primary px-5" :disabled="loading">
        <loading :show="loading"> Publicar </loading>
      </button>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions";

export default {
  props: {
    parent: Object,
    comment: Object,
    topic: [String, Number],
  },
  data() {
    const comment = this.comment || {};

    return {
      ...formFields(["text"], comment),
    };
  },
  methods: {
    submit() {
      if (this.loading) return;
      this.loading = true;

      const data = { ...this.form };

      if (this.parent) {
        data.parent_id = this.parent.id;
      }
      if (this.topic) {
        data.topic = parseInt(this.topic);
      }

      let action = "forum/addComment";
      if (this.comment) {
        action = "forum/updateComment";
        data.id = this.comment.id;
      }

      this.$store
        .dispatch(action, data)
        .then(() => {
          this.text = "";
          this.$message.success("Comentário salvo com sucesso");
          this.$emit("success");
        })
        .catch((error) => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.forum-comments-form {
  textarea {
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: none;
    color: white;
  }
}
</style>
