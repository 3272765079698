<template>
  <action-dispatcher
    action="forum/getTopic"
    :parameters="$route.params.id"
    class="view-forum container py-5"
  >
    <div class="row g-4">
      <div class="col-lg-3 col-sm-6 order-lg-1">
        <h2 class="mb-0 font-serif">Fórum</h2>

        <div class="small text-secondary mt-4">
          Este é o lugar ideal para trocar ideias e debater com respeito sobre
          diversos temas relacionados às Escrituras. Participem ativamente,
          mantendo o espírito construtivo que torna nossa comunidade vibrante.
          Sejam bem-vindos!
        </div>

        <topic-categories />
      </div>

      <div class="col-lg-3 col-sm-6 order-lg-3">
        <search-form placeholder="Pesquisar tópico..." route="forum" />

        <top-topics />
      </div>

      <div class="col-lg-6 order-lg-2">
        <div
          class="text-secondary mobile-small d-flex d-lg-block align-items-center mb-4"
        >
          <span>
            <router-link
              class="text-light text-decoration-none"
              :to="{ name: 'forum' }"
            >
              Fórum
            </router-link>
            <i class="fa-regular fa-chevron-right mx-2"></i>
          </span>

          {{ topic.name }}
        </div>

        <topic
          :topic="topic"
          class="mb-4"
          @edit="openEdit"
          @remove="openRemove"
        />

        <button class="btn btn-dark d-block w-100" @click="addDialog = true">
          <i class="fa-solid fa-reply me-1"></i> Responder
        </button>

        <action-dispatcher
          action="forum/getComments"
          :parameters="params"
          ref="commentsGet"
          :always-show-slot="!firstLoad"
          @success="firstLoad = false"
        >
          <comment
            v-for="comment in comments"
            :key="comment.id"
            :comment="comment"
            link
            class="my-4"
            :level="1"
            @edit="openEdit"
            @remove="openRemove"
            @reply="openReply"
          />
        </action-dispatcher>

        <div v-if="count > comments.length" class="text-center pt-4">
          <button class="btn btn-primary" @click="loadMore">
            Carregar mais
          </button>
        </div>
      </div>
    </div>

    <el-dialog v-model="addDialog" title="Criar comentário">
      <comments-form
        key="a"
        @success="refresh(true)"
        :topic="$route.params.id"
      />
    </el-dialog>

    <el-dialog v-model="replyDialog" title="Responder comentário">
      <comments-form
        key="e"
        @success="refresh(true)"
        :parent="selectedComment"
        :topic="$route.params.id"
      />
    </el-dialog>

    <el-dialog v-model="editDialog" title="Editar comentário">
      <comments-form
        v-if="editDialog"
        key="b"
        @success="refresh(true)"
        :comment="selectedComment"
      />
    </el-dialog>

    <confirmation
      v-model="removeDialog"
      @confirm="remove"
      text="Tem certeza que deseja remover comentário?"
      confirm-button-text="Remover"
    />
  </action-dispatcher>
</template>

<script>
import Topic from "@/components/forum/Topic";
import Comment from "@/components/forum/Comment";
import CommentsForm from "@/components/forum/CommentsForm";
import SearchForm from "@/components/SearchForm";
import TopTopics from "@/components/forum/TopTopics";
import TopicCategories from "@/components/forum/TopicCategories";
import { mapState } from "vuex";

export default {
  components: {
    Topic,
    Comment,
    CommentsForm,
    SearchForm,
    TopTopics,
    TopicCategories,
  },
  data() {
    return {
      firstLoad: true,
      addDialog: false,
      params: {
        offset: 0,
        limit: 5,
        topic: this.$route.params.id,
      },
      selectedComment: null,
      editDialog: false,
      removeDialog: false,
      replyDialog: false,
    };
  },
  computed: {
    ...mapState("forum", ["topic", "comments", "count"]),
  },
  beforeMount() {
    this.$store.dispatch("forum/resetComments");
  },
  methods: {
    remove() {
      this.removeDialog = false;
      this.$store
        .dispatch("forum/removeComment", this.selectedComment.id)
        .then(() => {
          this.$message.success("Comentário removido com sucesso");
          setTimeout(() => {
            this.refresh(true);
          }, 1999);
        })
        .catch(() => {
          this.$message.error("Erro");
        });
    },
    openReply(comment) {
      this.replyDialog = true;
      this.selectedComment = comment;
    },
    openEdit(comment) {
      this.editDialog = true;
      this.selectedComment = comment;
    },
    openRemove(comment) {
      this.removeDialog = true;
      this.selectedComment = comment;
    },
    refresh(reset = false) {
      if (reset) {
        this.$store.dispatch("forum/resetComments");
        this.firstLoad = true;
        this.params.offset = 0;
      }
      this.addDialog = false;
      this.editDialog = false;
      this.replyDialog = false;
      this.$refs.commentsGet.dispatch();
    },
    loadMore() {
      this.params.offset += this.params.limit;
      setTimeout(() => {
        this.refresh();
      }, 1);
    },
  },
};
</script>
