<template>
  <div class="forum-comment text-light">
    <div class="d-flex">
      <user-photo class="me-2" size="30" :photo="comment.user.picture" />

      <div class="d-flex flex-column overflow-hidden text-secondary">
        <h6 class="mb-0 text-light">
          {{ comment.user.name }}
        </h6>
        <small class="text-secondary fw-normal">{{ time }}</small>
      </div>

      <div class="flex-fill"></div>

      <el-dropdown v-if="comment.is_owner" trigger="click" class="el-dropdown">
        <span class="el-dropdown-link">
          <i class="fa-solid fa-ellipsis-vertical text-light fa-lg p-1"></i>
        </span>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="$emit('edit', comment)">
              Editar
            </el-dropdown-item>
            <el-dropdown-item @click="$emit('remove', comment)">
              Remover
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>

    <div class="comment-text-wrapper" :class="{ open }">
      <div
        class="mb-2 comment-text"
        v-html="comment.text"
        :id="`comment-text-${comment.id}`"
      />
      <button v-if="!open" @click="open = true">Ver mais</button>
    </div>

    <button
      class="btn btn-sm p-0 me-3"
      :class="{ 'text-primary': liked, 'text-secondary': !liked }"
      @click="like"
    >
      <i
        class="fa-heart me-1 fa-lg"
        :class="{ 'fa-solid': liked, 'fa-regular': !liked }"
      ></i>
      {{ likeCount }} curtida{{ likeCount === 1 ? "" : "s" }}
    </button>

    <button
      v-if="level < 2"
      class="btn btn-sm p-0 text-secondary"
      @click="$emit('reply', comment)"
    >
      <i class="fa-solid me-1 fa-reply fa-lg"></i> Responder
    </button>

    <comment
      v-for="child in comment.children"
      :key="child.id"
      :comment="child"
      :level="level ? level + 1 : 1"
      class="mt-2 ps-5 pe-0"
      @edit="$emit('edit', child)"
      @remove="$emit('remove', child)"
      @reply="$emit('reply', child)"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/pt";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);
dayjs.locale("pt");

export default {
  name: "comment",
  props: {
    comment: Object,
    level: Number,
  },
  data() {
    return {
      liked: this.comment.liked,
      likeCount: this.comment.like || 0,
      open: true,
    };
  },
  computed: {
    time() {
      return dayjs().to(dayjs(this.comment.created_at).add(3, "hour"));
    },
  },
  mounted() {
    const element = document.getElementById(`comment-text-${this.comment.id}`);
    const height = element.offsetHeight;
    if (height > 300) {
      this.open = false;
    }
  },
  methods: {
    like() {
      this.liked = !this.liked;
      this.$store
        .dispatch("forum/interact", {
          object_id: this.comment.id,
          like: this.liked,
          type: "comment",
        })
        .then(() => {
          if (this.liked) this.likeCount++;
          else this.likeCount--;
        })
        .catch(() => {
          this.liked = !this.liked;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variables";

.forum-comment {
  background: $dark;
  border-radius: 0;
  padding: 16px 16px 10px;
  font-size: 14px;
  position: relative;
  border-radius: 4px;

  .comment-text {
    ::v-deep * {
      white-space: pre-wrap;
      text-wrap: wrap;
      color: white;
    }
  }

  .comment-text-wrapper {
    position: relative;

    &:not(.open) .comment-text {
      max-height: 300px;
      overflow: hidden;
    }

    button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 100px 0 8px;
      border: none;
      background: linear-gradient(to bottom, transparent, $dark 90%);
      color: $primary;
      font-weight: bold;
    }
  }

  .btn-sm {
    font-size: 12px;
  }

  ::v-deep .dropdown {
    .dropdown-toggle::after {
      display: none;
    }
  }

  .btn-interact {
    color: white;
    display: inline-flex;
    align-items: center;

    i {
      font-size: 18px;
      margin-right: 6px;
    }
  }
}
</style>
